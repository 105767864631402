import React, { useState } from 'react';
import emailjs from 'emailjs-com';


const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        service: '',
    });
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');

    const services = [
        "Blow Out", "Men's Haircut", "Women's Cut (Trim)", "Women's Specialty Cut",
        "All-Over Color", "Color Retouch", "Color & Highlights", "Color & Balayage",
        "Partial Highlight", "Fartial", "Full Highlight", "Halo Placement",
        "Color Blocking", "Global Blonding", "Extra Bowl", "Root Tap",
        "Root melt", "Root Tap or Melt + Tone",
    ];
    const modalBackgroundStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000, // Ensure it's above everything else
    };

    const modalContentStyle = {
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '5px',
    };

    // Add padding-top and color styles to the "Close" button
    const closeButtonStyle = {
        paddingTop: '4px', // Add padding-top
        color: 'red',     // Set text color to red
    };


    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_syni1h5', 'template_qey5qj4', e.target, 'HoFkiCeSWO_P2AQQa')
    .then((result) => {
        console.log(result);
        setModalMessage('Your form has been submitted successfully!');
        setShowModal(true);
    })
    .catch((error) => {
        console.error(error);
        setModalMessage('An error occurred while submitting the form.');
        setShowModal(true);
    });
    };


    return (
        <div className="max-w-lg mx-auto my-10 shadow-lg rounded-lg p-8">
            <h2 className="text-2xl font-bold text-center text-gray-900 mb-6">Contact Me!</h2>
            <form onSubmit={handleSubmit}>
                <div className="mb-6">
                    <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900">Your Name</label>
                    <input type="text" name="name" id="name" onChange={handleChange} value={formData.name} required className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
                </div>
                <div className="mb-6">
                    <label htmlFor="phone" className="block mb-2 text-sm font-medium text-gray-900">Phone Number</label>
                    <input type="tel" name="phone" id="phone" onChange={handleChange} value={formData.phone} required className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
                </div>
                <div className="mb-6">
                    <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900">Email Address</label>
                    <input type="email" name="email" id="email" onChange={handleChange} value={formData.email} required className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
                </div>
                <div className="mb-6">
                    <label htmlFor="service" className="block mb-2 text-sm font-medium text-gray-900">Select a Service</label>
                    <select name="service" id="service" onChange={handleChange} value={formData.service} required className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                        <option value="">Select a service</option>
                        {services.map((service, index) => (
                            <option key={index} value={service}>{service}</option>
                        ))}
                    </select>
                </div>
                <button type="submit" className="text-black bg-pink-purple-gradient hover:scale-110 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Submit</button>
            </form>
            {showModal && (
                <div style={modalBackgroundStyle}>
                    <div style={modalContentStyle}>
                        <p>{modalMessage}</p>
                        <button onClick={() => setShowModal(false)} style={closeButtonStyle}>Close</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ContactForm;